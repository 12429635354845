<template>
  <div class="bg-white mb-5 py-lg-5 pb-5">
    <b-container id="exam-box">
      <h4 class="text-center my-4">
        2nd "Sail from Hangzhou" Live Channel Theory Exam Paper
      </h4>
      <b-button
        v-if="isSubmit"
        variant="primary"
        class="my-3"
        size="sm"
        @click="gotoHome"
      >Back to Homepage</b-button>
      <p v-if="score" class="text-right score">
        <el-tag v-if="score >= 60.0" type="success" style="font-size: 1.2rem">Score:  {{ score }}</el-tag>
        <el-tag v-else type="info" style="font-size: 1.2rem">Score: {{ score }}</el-tag>
      </p>
      <b-row v-for="(question, index) in questions" :id="`question-${question.id}`" :key="question.id" class="question-item">
        <b-col v-if="question.type === 1 || question.type === 2">
          <b-form-group>
            <p>
              <span>{{ index + 1 }}.</span>
              <span>
                <el-tag>Single Answer</el-tag>
                ({{ scores[question.type.toString()] }} Marks)&nbsp;{{ question.value }}
              </span>
            </p>
            <p v-if="res.hasOwnProperty(question.id)" class="tag-score mr-5">
              <el-tag v-if="res[question.id] === 1" type="success">Right</el-tag>
              <el-tag v-else type="danger">Wrong</el-tag>
            </p>
            <b-form-radio-group
              :id="question.id.toString()"
              v-model="answers[question.id][0]"
              :options="question.options"
              :name="question.id.toString()"
              class="ml-3"
              stacked
            />
          </b-form-group>
          <el-divider />
        </b-col>
        <b-col v-else>
          <b-form-group>
            <p>
              {{ index + 1 }}.
              <el-tag>Multiple Answer</el-tag>
              ({{ scores[question.type.toString()] }} Marks)&nbsp;{{ question.value }}
            </p>
            <p v-if="res.hasOwnProperty(question.id)" class="tag-score mr-5">
              <el-tag v-if="res[question.id] === 1" type="success">Right</el-tag>
              <el-tag v-else-if="res[question.id] === 0" type="warning">Half</el-tag>
              <el-tag v-else type="danger">Wrong</el-tag>
            </p>

            <b-form-checkbox-group
              :id="question.id.toString()"
              v-model="answers[question.id]"
              :options="question.options"
              :name="question.id.toString()"
              class="ml-3"
              stacked
            />
            <el-divider />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center" class="my-3">
        <b-col cols="8">
          <b-button
            v-if="isSubmit"
            variant="primary"
            block
            @click="gotoHome"
          >Back to Homepage</b-button>
          <b-button
            v-else
            variant="primary"
            block
            @click="submit"
          >Submit</b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-card v-if="!isSubmit" class="text-center py-0 footer py-0" body-class="pb-0">
      <font-awesome-icon icon="clock" size="2x" class="mb-2 d-none d-lg-inline" />
      <countdown :time="limit" :interval="1000" tag="p" @progress="handleCountdownProgress" @end="countDownEnd">
        <template slot-scope="props">
          <b-row class="count-down">
            <b-col class="px-0">{{ props.hours >= 10 ? props.hours : '0' + props.hours }}:</b-col>
            <b-col class="px-0">{{ props.minutes >= 10 ? props.minutes : '0' + props.minutes }}:</b-col>
            <b-col class="px-0">{{ props.seconds >= 10 ? props.seconds : '0' + props.seconds }}</b-col>
          </b-row>
          <b-row>
            <b-col class="px-0">H</b-col>
            <b-col class="px-0">M</b-col>
            <b-col class="px-0">S</b-col>
          </b-row>
        </template>
      </countdown>
    </b-card>
  </div>
</template>

<script>
import { takeAnExam, submit } from '@/api/exam'
import { Loading } from 'element-ui'

export default {
  name: 'ExamList',
  data() {
    return {
      examId: null,
      questions: [], // 所有问题项
      scores: {}, // 每种题型的分值
      answers: {}, // 用户提交的答案
      limit: 0, // 当前试卷剩余答题时间
      tempLimit: 0, // 当前试卷剩余答题时间备份，用于判断是否到了交卷时间
      minTime: 0, // 允许提交试卷的最短剩余答题时间(答题总限制时间-答题最少时间)
      res: [], // 用户的答题结果
      score: null, // 用户的得分
      isSubmit: false,
      startCountDown: false
    }
  },
  created() {
    this.getQuestions()
  },
  methods: {
    /**
     * 获取试题
     */
    getQuestions() {
      const loadingIns = Loading.service({
        text: 'generating',
        fullscreen: true
      })
      takeAnExam().then(e => {
        const data = e.data
        this.examId = data.examId

        let id = null
        let value = ''
        let type = null
        let options = []

        this.scores = data.scores
        data.questions.forEach(item => {
          id = item.id
          value = item.question
          type = item.type
          options = [
            { text: item.a, value: 'A', disabled: false },
            { text: item.b, value: 'B', disabled: false }
          ]
          if (type !== 1) {
            options.push(
              { text: item.c, value: 'C', disabled: false },
              { text: item.d, value: 'D', disabled: false }
            )
          }
          this.questions.push({ id, value, type, options })
          this.answers[id] = []
        })
        this.limit = data.limitTime * 1000
        this.minTime = data.minTime * 1000
        this.startCountDown = true
        loadingIns.close()
      })
        .catch(err => {
          loadingIns.close()
          if (err.code === 401) {
            this.$router.push('/login')
          }
        })
    },
    /**
     * 答案提交
     */
    submit() {
      if (this.tempLimit > this.minTime) {
        this.$message({
          message: 'Not due for submission yet',
          type: 'warning'
        })
        return
      }
      const data = []
      let firstEmptyEl = ''
      Object.keys(this.answers).some(key => {
        if (this.answers[key].length === 0) {
          firstEmptyEl = '#question-' + key.toString()
          return true
        }
        this.answers[key].sort()
        data.push({ 'questionId': key, 'answer': this.answers[key].join(';') })
      })

      if (firstEmptyEl) {
        this.goAnchor(firstEmptyEl)
        this.$message({
          message: 'Option cannot be empty!',
          type: 'warning'
        })
        return
      }
      this.$confirm('', 'Your answers cannot be changed once submitted. Are you sure to continue?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true
      }).then(() => {
        this.$loading({ text: 'Submitting' })
        submit({ examId: this.examId, examData: JSON.stringify(data) }).then(e => {
          this.res = e.data.res
          this.score = e.data.score
          this.isSubmit = true
          this.$loading({ text: 'Submitting' }).close()
          this.$message({
            message: 'Submitted successfully',
            type: 'success'
          })
          this.gotoHome()
        }).catch(() => {
          this.$loading({ text: 'Submitting' }).close()
        })
      }).catch(() => {})
    },
    /**
     * 考试计时结束的回调
     */
    countDownEnd() {
      const data = []
      Object.keys(this.answers).some(key => {
        this.answers[key].sort()
        data.push({ 'questionId': key, 'answer': this.answers[key].join(';') })
      })
      if (this.startCountDown) {
        this.$loading({ text: 'Submitting' })
        submit({ examId: this.examId, examData: JSON.stringify(data) }).then(e => {
          this.res = e.data.res
          this.score = e.data.score
          this.isSubmit = true
          this.$loading({ text: 'Submitting' }).close()
          this.$message({
            message: 'Submitted successfully',
            type: 'success'
          })
          this.gotoHome()
        }).catch(() => {
          this.$loading({ text: 'Submitting' }).close()
        })
      }
    },
    /**
     * 滚动到锚点
     * @param selector
     */
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },
    gotoHome() {
      this.$router.push({
        path: '/exam'
      })
    },
    handleCountdownProgress(data) {
      this.tempLimit = data.totalSeconds * 1000
    }
  }
}
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 175px;
  background: rgba(255, 255, 255, 0.5);
}
.tag-score {
  position: absolute;
  right: 0;
}
.score {
  font-size: 1.5rem;
  color: red;
}
</style>
