import request from '@/utils/request'

export function takeAnExam() {
  return request({
    url: '/exam/takeAnExam',
    method: 'get'
  })
}

export function submit(data) {
  return request({
    url: '/exam/submit',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/exam/getInfo',
    method: 'get'
  })
}

export function getHistory() {
  return request({
    url: '/exam/getHistory',
    method: 'get'
  })
}

export function getCert() {
  return request({
    url: '/exam/getCert',
    method: 'get'
  })
}
